import React, { Suspense, lazy, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";

import Routes from "configs/constants/routes";
import GlobalStyles from "./global-styles";

import { DefaultLayout } from "layouts";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducer";
import { Themes } from "theme/theme";
import LoadSpinner from "components/SpinnerLoading";
import LoadingComponent from "components/Loading";
import { coinPrice, getProfile } from "features/userLogin/userLoginSlice";

declare global {
  interface Window {
    ethereum: any;
  }
}

const Dashboard = lazy(() => import("pages/Dashboard"));
const Home = lazy(() => import("pages/Home"));

const Login = lazy(() => import("pages/Auth/Login"));
const Register = lazy(() => import("pages/Auth/SignUp"));
const ForgotPassword = lazy(() => import("pages/Auth/ForgotPassword"));

const TermPersonal = lazy(() => import("pages/Terms/TermPersonal"));
const TermUse = lazy(() => import("pages/Terms/TermUse"));
const BuyCoin = lazy(() => import("pages/BuyCoin"));
const SaleCoin = lazy(() => import("pages/SaleCoin"));
const Transactions = lazy(() => import("pages/Transactions"));
const Profile = lazy(() => import("pages/Profile"));
const MyCoin = lazy(() => import("pages/MyCoin"));
const ListInviter = lazy(() => import("pages/ListInviter"));

// const renderLoader = () => <Loading />;
const renderLoader = () => <LoadingComponent />;

const AppWrapper = styled.div`
  height: 100%;
`;

function App(props: any) {
  const dispatch = useDispatch();

  const isOpenLoading = useSelector(
    (state: RootState) => state.loadingAction.isOpen
  );

  useEffect(() => {
    //routes change
    props.history.listen((location: any, action: any) => {
      window.scrollTo(0, 0);
    });

    // dispatch(getProfile())
  }, []);

  useEffect(() => {
    dispatch(coinPrice())
  }, []);

  return (
    <ThemeProvider theme={Themes}>
      <AppWrapper>
        <Switch>
          <DefaultLayout>
            <Suspense fallback={renderLoader()}>
              <Route exact path={Routes.DASHBOARD} component={Dashboard} />
              {/* <PublicRoute exact path={Routes.AUTH_LOGIN} component={Login} />
              <PublicRoute
                exact
                path={Routes.AUTH_REGISTER}
                component={Register}
              />
              <PublicRoute
                exact
                path={Routes.AUTH_FORGOT_PASSWORD}
                component={ForgotPassword}
              /> */}
              <Route
                exact
                path={Routes.TERM_PERSONAL}
                component={TermPersonal}
              />

              <Route exact path={Routes.TERM_USE} component={TermUse} />
              <Route exact path={Routes.BUY_COIN} component={BuyCoin} />
              <Route exact path={Routes.SALE_COIN} component={SaleCoin} />
              <Route
                exact
                path={Routes.TRANSACTIONS}
                component={Transactions}
              />
              <Route exact path={Routes.PROFILE} component={Profile} />
              <Route exact path={Routes.MY_COIN} component={MyCoin} />
              <Route exact path={Routes.LIST_INVITER} component={ListInviter} />
              {isOpenLoading && <LoadSpinner isOverlay={true} />}
              {isOpenLoading && <LoadingComponent />}
            </Suspense>
          </DefaultLayout>
        </Switch>

        <GlobalStyles />
      </AppWrapper>
    </ThemeProvider>
  );
}

export default withRouter(App);

function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  // console.log(rest, TargetPage);
  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.getItem("token") ? (
          <TargetPage {...props} />
        ) : (
          <Redirect to={{ pathname: Routes.DASHBOARD }} />
        )
      }
    />
  );
}

function PrivateRoute({
  component: TargetPage,
  isAuthenticated,
  ...rest
}: any): React.ReactElement {
  // console.log(isAuthenticated, 'isAuthenticated');
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <TargetPage {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.AUTH_LOGIN,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
