const Fonts = {};

const Themes = {
  default: {
    mainWhite: "#FFFFFF",
    containerBg: "#F2F2F2",
    innerContBg: "#E5E5E5",
    tableLabel: "#BFC5D2",
    primaryBtnBg: "#2E5BFF",
    successBtn: "#6FD15E",
    successBtnHover: "#82e371",
    inputLabel: "#B0BAC9",
    navbarBoxShadow: "0px 12px 24px rgba(46, 91, 255, 0.1)",
  },
};

const darkTheme = {
  mainColor: "#FF9900",
  grayLight: "#DDDDDD",
  mainTextColor: "#999999",
  mainBgColor: "#191919",
  secondaryBgColor: "#232323",
  tertiaryBgColor: "#333333",
  doteColor: "#555555",
  losingBgColor: "#FF5454",
  winningBgColor: "#56E19F",
  referralBgColor: "#202020",
  newTabBgColor: "#000000",

  //input
  inputBg: "#202020",
  inputLabelColor: "#BBBBBB",
  inputColor: "#777777",

  //text color
  textWhite: "#FFFFFF",
  textWinning: "#28DD86",
  textError: "#FF3D00",
  textLosing: "#FF5454",
  textLabel: "#BBBBBB",
  textLossHistory: "#FF2E00",
  textWinHistory: "#00FF58",
  textYellowHistory: "#FF9900",
  textGreyHistory: "#777777",
  textHeaderTable: "#AFAFAF",

  //profit color
  textSmallProfitColor: "#28DD86",
  textMediumProfitColor: "#FFC225",
  textLargeProfitColor: "#FF5454",
  textExtraLargeProfitColor: "#FF3D00",

  //menu
  menuColor: "#AAAAAA",
  menuActiveColor: "#FFFFFF",

  //notice
  noticePrimaryColor: "#777777",
  noticeSecondaryColor: "#FFFFFF",
  noticeTertiaryColor: "#FF451C",
  noticeSloganColor: "#FF9900",

  //hedge
  hedgePrimaryColor: "#777777",
  hedgeSecondaryColor: "#FFFFFF",
  hedgeLongColor: "#28DD86",
  hedgeShortColor: "#FF5454",
  hedgeProfitColor: "#FF3D00",
  hedgeBtnDisableColor: "rgb(255 255 255 / 40%)",
  hoverHedgeLongColor: "#38E793",
  hoverHedgeShortColor: "#FF5F5F",

  //timming
  timingTitleColor: "#BBBBBB",
  timingCountdowColor: "#FFFFFF",

  //tab
  defaulTabBgColor:
    "linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, rgba(255, 255, 255, 0.1) 100%)",
  winningTabBgColor:
    "linear-gradient(180deg, rgba(86, 225, 159, 0.2) 0%, rgba(86, 225, 159, 0) 100%)",
  losingTabBGColor:
    "linear-gradient(180deg, rgba(255, 84, 84, 0.2) 0%, rgba(255, 84, 84, 0) 100%)",
  defaultHoverTabBgColor: "#FFFFFF15",

  //button
  defaultHoverCloseTabBtnColor: "#FFFFFF20",

  defaultButtonBgColor: "#FF9900",
  defaultTextBtnColor: "#FFFFFF",
  defaultHoverButtonBgColor: "#FFAD33",
  defaultButtonBorderColor: "#FF9900",

  oulinedButtonBgColor: "#191919",
  outlinedTextBtnColor: "#FF9900",
  outlinedHoverButtonBgColor: "#FFFFFF10",
  outlinedButtonBorderColor: "#FF9900",

  //hidden tab
  hiddenTabBGColor: "#00000060",
  hoverHiddenTabBGColor:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(0, 0, 0, 0.4)",
  hiddenTabTitleColor: "#FFFFFF",
  hiddenTabWinTextColor: "#28DD86",
  hiddenTabLoseTextColor: "#FF5454",
  hiddenTabTimeTextColor: "#777777",

  //quote button
  quoteColor: "#999999",
  quoteBgColor: "#333333",

  //pagination button
  paginationBtnColor: "#777777",
  paginationBtnHoverBgColor: "#FF000012",
  paginationBtnActiveColor: "#FF9900",

  //popup
  faileColor: "#FF2E00",
  resultPercentLose: "#777777",
  resultPercentWin: "#28DD86",
  textRewardLabel: "#9D9D9D",
  textRewardValue: "#FF9900",

  cardBgColor: "#2a3042",

  cardShadow: "0 0.75rem 1.5rem rgb(18 38 63 / 3%)",

  borderColor: "#343b51",
  borderDropdown: "#333333",
  inputBorderColor: "#8699ad",
  borderWatchingTabColor: "#FFC255",
  borderWhite: "#FFFFFF",
  borderDialog: "#808080",

  tableBgHead: "#22242f",
};

const lightTheme = {
  mainTextColor: "#5b626b",

  mainBgColor: "#f8f8fa",
  secondaryBgColor: "#ececf1",
  cardBgColor: "#ffffff",

  cardShadow: "0 0 13px 0 rgb(236 236 241 / 44%)",

  borderColor: "#e9ecef",
  inputBorderColor: "#666666",

  tableBgHead: "#f3f4f6",
};

const FontENG = {
  NotoSansKRRegular: "'NotoSansKR-Regular'",
  NotoSansKRMedium: "'NotoSansKR-Medium'",
  NotoSansKRBold: "'NotoSansKR-Bold'",
  Dystopian: "'Dystopian'",
  FONTSPRINGDEMORocGrotesk: "'FONTSPRING DEMO - Roc Grotesk'",
};
const FontVIE = {
  NotoSansKRRegular: "'NotoSans-Regular'",
  NotoSansKRMedium: "'NotoSans-Medium'",
  NotoSansKRBold: "'NotoSans-Bold'",
  Dystopian: "'Montserrat-Regular'",
  FONTSPRINGDEMORocGrotesk: "'Montserrat-Regular'",
};
const FontKOR = {
  NotoSansKRRegular: "'NotoSansKR-Regular'",
  NotoSansKRMedium: "'NotoSansKR-Medium'",
  NotoSansKRBold: "'NotoSansKR-Bold'",
  Dystopian: "'NotoSansKR-Regular'",
  FONTSPRINGDEMORocGrotesk: "'NotoSansKR-Regular'",
};

export { Fonts, Themes, lightTheme, darkTheme, FontENG, FontKOR, FontVIE };
