import BigNumber from 'bignumber.js'
import { emptyChainInfo, networks } from 'configs/constants/chains';
import reactLocalStorage from "utils/localStorage";
import { ChainId, ChainInfo, ChainName, LOCAL_CONFIG_KEY, Theme } from 'configs/constants/types'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const getInitialChainId = (): ChainId => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10) || 5;

  const localItem: any = reactLocalStorage.get(LOCAL_CONFIG_KEY, "")
  const item = localItem ? JSON.parse(localItem) : ''
  const itemId = item?.chainId || chainId
  return Number(itemId)
}

let _chainId = getInitialChainId()

export const _setChainId = (newChainId: ChainId) => {
  _chainId = newChainId
}

let chains: any[] = [];
chains = [...networks]

export const getNetworkDefault = () => {
  const chainIdDefault = Number(process.env.REACT_APP_CHAIN_ID)
  const network = networks.find((c) => c.chainId === chainIdDefault);

  return network
}

export const getChains = (): any => {
  return chains
}

export const _getChainId = (): ChainId => {
  return _chainId
}

export const getChainById = (chainId: any): any => {
  return getChains().find((chain) => chain.chainId === chainId) || emptyChainInfo
}

export const getChainInfo = (): ChainInfo => {
  return getChainById(_getChainId())
}

export const getChainName = (): ChainName => {
  return getChainInfo().chainName
}

export const getChainTheme = (): Theme => {
  return getChainInfo().theme
}

export function normalizeChainId(chainId: string | number) {
  if (typeof chainId === 'string') {
    const isHex = chainId.trim().substring(0, 2)

    return Number.parseInt(chainId, isHex === '0x' ? 16 : 10)
  }
  return chainId
}