const Routes = {
  LOGIN: "/login",
  MYPAGE_CHANGE_PASSWORD: "/mypage/change-password",
  AUTH_LOGIN: "/sign-in",
  AUTH_REGISTER: "/sign-up",
  AUTH_FORGOT_PASSWORD: "/forgot-password",

  NOTICE: "/notice",
  TERM_PERSONAL: "/term-personal",
  TERM_USE: "/term-use",

  DASHBOARD: "/",
  COMING_SOON: "/coming-soon",
  BUY_COIN: "/buy-coin",
  SALE_COIN: "/sale-coin",
  TRANSACTIONS: "/transactions",
  WITHDRAWAL: "/withdrawal",
  PROFILE: "/profile",
  MY_COIN: "/my-coin",
  LIST_INVITER: "/list-inviter"
};

export default Routes;
