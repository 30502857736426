import { createSlice } from "@reduxjs/toolkit";
import { getCoinPrice, profile } from "apis";

export interface LoadingActionState {
  userInfo: any;
  priceCoin: any;
}

const initialState: LoadingActionState = {
  userInfo: {},
  priceCoin: {
    BUY_USDT: 24560,
    BUY_BNB: 5968080,
    SELL_USDT: 23560,
    SELL_BNB: 5725080,
  },
};

const userInfo = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setPriceCoin(state, action) {
      state.priceCoin = action.payload;
    },
  },
});

export const { setUserInfo, setPriceCoin } = userInfo.actions;

export const getProfile = () => async (dispatch) => {
  try {
    const response: any = await profile();
    dispatch(setUserInfo(response?.data));
    return response;
  } catch (error) {
    return error;
  }
};

export const coinPrice = () => async (dispatch) => {
  try {
    const response: any = await getCoinPrice();
    console.log('response', response);
    
    if (response?.result) {
      dispatch(
        setPriceCoin({
          BUY_USDT: response?.data?.buyUsdt,
          BUY_BNB: response?.data?.buyBnb,
          SELL_USDT: response?.data?.sellUsdt,
          SELL_BNB: response?.data?.sellBnb,
        })
      );
    }
    return response;
  } catch (error) {
    return error;
  }
};

export default userInfo.reducer;
