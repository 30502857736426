const ikon = require("./ikon.ttf");
const themify = require("./themify.woff");
const fabrands400 = require("./fa-brands-400.woff2");
const fasolid900 = require("./fa-solid-900.woff2");


export {
  ikon,
  themify,
  fasolid900,
  fabrands400
};
