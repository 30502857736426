import React from "react";
import LoadingImg from "assets/images/home/loading.png";

import * as S from "./style";
import { useTranslation } from "react-i18next";

function LoadingComponent() {
  const { t } = useTranslation();

  return (
    <S.Container>
      <div className="wrapper">
        <S.Loading>
          <p className="label">{t("LOADING")}...</p>
          <span>
            <img src={LoadingImg} />
          </span>
        </S.Loading>
      </div>
    </S.Container>
  );
}

export default LoadingComponent;
