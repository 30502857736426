import { mainAxios } from "libs/axios";

export const login = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/auth/login`,
    payload: payload
  })
};

export const profile = () => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/auth/profile`,
    requiresToken: true
  })
};

export const signUp = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/user/register`,
    payload: payload
  })
};

export const forgotPassword = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/auth/recovery-pw`,
    payload: payload
  })
}

export const updateProfile = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/user/update`,
    payload: payload,
    requiresToken: true
  })
}

export const changePassword = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/auth/change-password`,
    payload: payload,
    requiresToken: true
  })
}

export const changeWallet = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/user/update-wallet`,
    payload: payload,
    requiresToken: true
  })
}