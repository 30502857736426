import React from "react";

import * as S from "./style";

interface Props {
  children?: React.ReactNode;
}

function DefaultLayout(props: Props) {
  const { children } = props;

  return (
    <S.Wrapper>
      <div className="main-content">{children}</div>
    </S.Wrapper>
  );
}

export default DefaultLayout;
