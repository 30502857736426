import { createSlice } from "@reduxjs/toolkit";

export interface ConnectWalletState {
  isOpenWallet: boolean;
  isOpenChangeNetwork: boolean;
}

const initialState: ConnectWalletState = {
  isOpenWallet: false,
  isOpenChangeNetwork: false,
};

export const connectWalletSlice = createSlice({
  name: "popupSwicth",
  initialState,
  reducers: {
    openWallet: (state) => {
      state.isOpenWallet = true;
    },
    closeWallet: (state) => {
      state.isOpenWallet = false;
    },
    openChangeNetwork: (state) => {
      state.isOpenChangeNetwork = true;
    },
    closeChangeNetwork: (state) => {
      state.isOpenChangeNetwork = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openWallet,
  closeWallet,
  openChangeNetwork,
  closeChangeNetwork,
} = connectWalletSlice.actions;

export default connectWalletSlice.reducer;
