import { networks } from "configs/constants/chains";
import random from "lodash/random";
import { getNetworkDefault } from "./chainInfo";

const network = getNetworkDefault();

// Array of available nodes to connect to
export const nodes = [network?.rpcUrls?.[0]];
export const explorer = [network.blockExplorerUrls[0]];
export const chainName = [network.chainName];
export const currencyName = [network.nativeCurrency.name];
export const symbol = [network.nativeCurrency.symbol];
export const decimals = [network.nativeCurrency.decimals];

export const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1);
  return nodes[randomIndex];
};

export const getExplorerUrl = () => {
  return explorer;
};
