import { createSlice } from '@reduxjs/toolkit'

export interface LoadingActionState {
  isOpen: boolean,
  is_loading: boolean
}

const initialState: LoadingActionState = {
  isOpen: false,
  is_loading: false
}

export const loadingActionSlice = createSlice({
  name: 'loadingSwicth',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true
    },
    close: (state) => {
      state.isOpen = false
    },
    setLoadAction(state, action) {
      state.is_loading = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { open, close, setLoadAction } = loadingActionSlice.actions

export default loadingActionSlice.reducer