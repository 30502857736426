import React from "react";
import * as S from "./style";

interface Props {
  isOverlay?: boolean;
}

function LoadSpinner(props: Props): React.ReactElement {
  const { isOverlay } = props;
  return (
    <S.LoadSpinner>
      <div className="spinner-content">
        {/* <div className="lds-spinner">
              <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div> */}
        {/* <img className="image-loading" src={Loading} alt="loading" /> */}
      </div>
      {isOverlay && <div className="overlay"></div>}
    </S.LoadSpinner>
  );
}

export default LoadSpinner;
