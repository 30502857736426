import { ChainInfo } from './types'

export const networks: Array<ChainInfo> = [
  {
    chainName: 'Ethereum',
    chainId: 1,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://eth-mainnet.g.alchemy.com/v2/aA6dwkxofCBuCVmMWnjRsPEAWjOa8pVx'],
    blockExplorerUrls: ['https://etherscan.io/'],
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#3d69d4',
    },
    baseUrl: {
      pro: `https://api.keno23.com/`,
      dev: `https://api.keno23.com/`
    }
  },
  {
    chainName: 'Goerli Ethereum',
    chainId: 5,
    nativeCurrency: {
      name: 'gETH',
      symbol: 'gETH',
      decimals: 18,
    },
    rpcUrls: ['https://eth-goerli.g.alchemy.com/v2/jTnxcfhEcDQw_nPchjgQJGge0q3NpCyl'],
    blockExplorerUrls: ['https://goerli.etherscan.io/'],
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#3d69d4',
    },
    baseUrl: {
      pro: `https://api.keno23.com/`,
      dev: `https://api.keno23.com/`
    }
  },
  {
    chainName: 'Smart Chain Testnet',
    chainId: 97,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://bscscan.com/'],
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#a566ff',
    },
    baseUrl: {
      pro: `https://api.keno23.com/`,
      dev: `https://api.keno23.com/`
    }
  },
]


export const emptyChainInfo: ChainInfo = {
  chainId: null,
  chainName: '',
  shortName: '',
  nativeCurrency: {
    name: '',
    symbol: '',
    decimals: 0,
    logoUri: '',
  },
  theme: { textColor: '', backgroundColor: '' },
  rpcUrls: [],
  blockExplorerUrls: [],
  baseUrl: { pro: '', dev: '' },
}
