import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .main-content {
    flex: 1;
  }

  /* .main-content {
    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    flex-grow: 1;
    position: relative;
    margin-bottom: 50px;
  } */
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  /* margin-top: 60px; */
  padding-top: 60px;
  height: 100%;
`;
