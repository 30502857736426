import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { Router } from "react-router";
import { Web3ReactProvider } from "@web3-react/core";
import { createBrowserHistory } from "history";
import { getLibrary } from "utils/web3React";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "theme/vendor.css";
import "theme/styles.css";

const history = createBrowserHistory();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router history={history}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Router>
      </PersistGate>
    </Provider>
  </Web3ReactProvider>
  // ,
  // document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
