import { mainAxios } from "libs/axios";

export const createTransactionBuy = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/transaction/buy`,
    payload: payload,
  })
};

export const createTransactionSell = (
  payload: any
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/api/v1/public/transaction/sell`,
    payload: payload,
  })
};

export const getListTransaction = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/api/v1/public/transaction/list`,
    requiresToken: true
  });
};

export const getCoinPrice = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/api/v1/public/transaction/coin-price`,
    requiresToken: true
  });
};

