import { mainAxios } from "libs/axios";

export const checkReferralCodeSignup = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/api/v1/user/check-referral-code`,
    params,
    requiresToken: true
  });
};

export const getListInviter = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/api/v1/public/report/list-inviter`,
    requiresToken: true
  });
};