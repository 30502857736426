export const LOCAL_CONFIG_KEY = 'ChainInfo'
export type ChainName = ChainInfo['chainName']
export enum ChainId {
  // MAINNET = 56,
  // TESTNET = 97,
  MAINNET = 42161,
  TESTNET = 421613
}

export interface Address {
  97?: string
  56?: string
  1?: string
  5?: string
  421613?: string
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type Theme = {
  textColor: string
  backgroundColor: string
}

export type BaseServer = {
  pro: string
  dev: string
}

export type CampaignType = 'ifo' | 'teambattle' | 'participation'


export enum FetchStatus {
  Idle = 'IDLE',
  Fetching = 'FETCHING',
  Fetched = 'FETCHED',
  Failed = 'FAILED',
}

export type NativeCurrency = {
  name: string
  symbol: string
  decimals: number
  logoUri?: string
}

export type ChainInfo = {
  chainId: number // Restricted by what is returned by the CGW
  chainName?: string
  shortName?: string
  nativeCurrency?: NativeCurrency
  rpcUrls?: Array<string>
  blockExplorerUrls?: any
  theme?: Theme,
  baseUrl?: BaseServer
}
