import styled, { css } from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #000;
  z-index: 100;

  .wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    place-self: center;
  }
`;

export const Loading = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .label {
    font-family: ${(props) => props.theme.Dystopian}, sans-serif;
    font-size: 42px;
    line-height: 50px;
    color: #ffffff;
  }

  img {
    width: 146px;
    height: 260px;
  }
`;
