import { createGlobalStyle } from "styled-components";
import { darkTheme } from "theme/theme";

import {
  ikon,
  themify,
  fasolid900,
  fabrands400
} from "theme/fonts/fonts";

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'ikon';
    src: local('ikon'), url(${ikon}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block
  }
  @font-face {
    font-family: 'themify';
    src: local('themify'), url(${themify}) format('woff');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: Font Awesome\ 5 Brands;
    src: local('fabrands400'), url(${fabrands400}) format('woff2');
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: Font Awesome;
    src: local('fasolid900'), url(${fasolid900}) format('woff2');
    font-style: normal;
    font-weight: 900;
  }
  
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    font-family: "Montserrat", Sans-serif;
  }

  body {
    color: ${(props: any) => props.theme.mainTextColor};
  }

  #root {
    height: 100%;
  }

  button{
    font-family: "Montserrat", Sans-serif;
  }

  a{
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }

  h1, h2, h3, h4, h5, h6{
    color: ${(props: any) => props.theme.mainTextColor} !important;
  }

  
  .select-form{
    min-width: 200px;
    &.w-150{
      min-width: 150px;
    }
  }
  .flex-row{
    display: flex;
    align-items: center;
  }
  .label-table{
    margin-left: 10px;
  }
  .check-box-custom{
    margin: 0 30px 0 0 !important;
    > span{
      &:hover{
        background-color: rgba(86,100,210,0.04);
      }
    }
  }
  .label-btn{
    cursor: pointer;
    font-weight: bold;
    white-space: nowrap;
  }
  .btn-edit{
    color: #5664d2;
  } 
  .btn-delete{
    color: #d14343;
  }
  .success{
    color: #1e7e34 !important;
  }
  .warning{
    color: #d14343 !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
  }
  .img-heading{
    object-fit: cover;
    width: 100%;
  }
  .mb-20{
    margin-bottom: 20px;
  }
  .no-mg{
    margin: 0 !important;
  }
  .mw-30{
    min-width: 30%;
  }
  .selelct-data{
    min-width: 300px;
  }
  .table-note{
    margin: 10px 0;
  }
  .table-note-title{
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
  }
  /* .btn{
    border: none;
    cursor: pointer;
  }
  .input{
    height: 36px;
    display: flex;
    min-width: 200px;

    background: ${(props: any) => props.theme.inputBg};
    color: ${(props: any) => props.theme.inputColor};
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    padding: 8px 14px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #545454 !important;
  } */
  .input-table{
    border: 1px red solid;
    /* height: 36px; */
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.42);
    display: flex;
    align-items: center;
    min-width: 200px;
    .MuiFormHelperText-root{
      width: 100%;
    }
    &.currency{
      .MuiOutlinedInput-adornedStart{
        padding-left: 0;
        &.Mui-focused, &:hover{
          input{
            border: 1px solid ${(props: any) => props.theme.mainColor};
          }
        }
        &.Mui-error{
          input{
            border: 1px solid ${(props: any) => props.theme.textError};
          }
        }
      }
      .MuiInputAdornment-root{
        display: none;
      }
      .MuiFormHelperText-root{
        font-size: 12px;
        line-height: 20px;
        @media (max-width: 1700px){
          font-size: 10px;
        }
        &.Mui-error{
          color: ${(props: any) => props.theme.textError};
          font-family: "Montserrat", Sans-serif;
        }
      }
    }
    .MuiOutlinedInput-notchedOutline{
      border: none;
    }
    /* input{
      padding: 10px;
      height: 100%;
      background: ${(props: any) => props.theme.tertiaryBgColor};
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      color: ${(props: any) => props.theme.textWhite};
      height: 46px;
      padding: 0 15px;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      box-sizing: border-box;
      font-family: "Montserrat", Sans-serif;
      &:placeholder{
        color: ${(props: any) => props.theme.hedgePrimaryColor};
      }
      &.error{

      }
      &.correct{
        
      }
    }
    textarea{
      padding: 0 10px;
      height: 100%;
    } */
    &.txt-area{
      height: 100%;
    }
  }
  .numeric-format{
    border: 1px red solid;
    /* height: 36px; */
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.42);
    display: flex;
    align-items: center;
    min-width: 200px;
    padding: 10px;
    height: 100%;
    background: ${(props: any) => props.theme.tertiaryBgColor};
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: ${(props: any) => props.theme.textWhite};
    height: 46px;
    padding: 0 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    box-sizing: border-box;
    font-family: "Montserrat", Sans-serif;
    outline: 0;
    width: 100%;
    &:placeholder{
      color: ${(props: any) => props.theme.hedgePrimaryColor};
    }
    &:focus, &:hover{
      border: 1px solid ${(props: any) => props.theme.mainColor};
    }
  }
  .full-screen{
    padding: 0 27px;
    @media (max-width: 991px){
      padding: 0 18px;
    }
  }
  .err-txt{
    font-size: 12px;
    line-height: 20px;
    color: ${(props: any) => props.theme.textError};
    font-family: "Montserrat", Sans-serif;
    margin-top: 4px;
    @media (max-width: 1700px){
      font-size: 10px;
    }
  }

  ::placeholder {
    font-family: "Montserrat", Sans-serif;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: "Montserrat", Sans-serif;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: "Montserrat", Sans-serif;
  } 

.asset-overview{
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 500px;
  .ant-popover-title{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #999999;
    margin-bottom: 14px;
  }
  .ant-popover-inner{
    border-radius: 0;
    background: #232323;
    padding: 20px;
  }
}
  .custom-ant-tabs{
    font-family: "Montserrat", Sans-serif;
    width: 100%;
    .ant-tabs-nav{
      margin-bottom: 20px;
      &:before{
        display: none;
      }
    }
    .ant-tabs-tab{
      padding: 4px 0;
      .ant-tabs-tab-btn{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #555555;
        &:focus:not(:focus-visible){
          color: #FFFFFF;
        }
      }
      &.ant-tabs-tab-active{
        .ant-tabs-tab-btn{
          color: #FFFFFF;
        }
      }
    }
    .ant-tabs-ink-bar{
      background: #FF9900;
    }
  }

  .custom-table{
    .ant-table{
      font-family: "Montserrat", Sans-serif;
    table {
      border-radius: 0;
    }
    } 
    .ant-table-thead{
      > tr > th, > tr > td {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #999999;
        background: #232323 !important;
        border: none;
        border-radius: 0 !important;
        padding: 8px 10px;
        &:before {
          display: none;
        }
      }
      .ant-table-column-sorters{
        justify-content: flex-start;
        .ant-table-column-title{
          flex: none;
        }
      }
      .ant-table-column-sorter-inner{
        .anticon {
          color: #444444;
          &.active{
            color: #AFAFAF;
          }
        }
      }
    } 
    .ant-table-tbody > tr > td {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #ffffff;
      border-bottom: 1px solid #232323;
      background: #191919;
      padding: 10px;
      min-height: 56px;
      p {
        margin: 0;
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #232323;
    }
    .ant-table-tbody > tr.ant-table-row > .ant-table-cell.left-border {
      border-left: 1px solid #232323;
    }
    .ant-table-tbody > tr.ant-table-row > .ant-table-cell.right-border {
      border-right: 1px solid #232323;
    }
    .nowrap{
      white-space: nowrap;
    }
    .ant-pagination {
      margin-top: 20px;
      .ant-pagination-item-link {
        color: #ffffff;
        border: none;
      }
      .ant-pagination-item {
        background: none;
        border: none;
        color: #777777;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        min-width: 30px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        &.ant-pagination-item-active, &.ant-pagination-item-active a {
          color: #ff9900;
        }
      }
      .ant-pagination-jump-next,
      .ant-pagination-jump-prev{
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-pagination-item-ellipsis{
        color: #777777;
      }
      .ant-pagination-item-link-icon{
        color: #ffffff;
      }
      .ant-pagination-next,
      .ant-pagination-prev {
        min-width: 24px;
        height: 24px;
      }
      .ant-pagination-prev {
        margin-right: 12px;
      }
      .ant-pagination-next {
        margin-left: 8px;
      }
      .ant-pagination-next .ant-pagination-item-link,
      .ant-pagination-prev .ant-pagination-item-link {
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-pagination-disabled .ant-pagination-item-link {
        color: #777777;
      }
    }
    .ant-checkbox-inner{
      background: transparent;
      border-color: #ffffff !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner{
      background: #FF9900;
      &:after{
        display: none !important;
      }
    }
    .ant-checkbox-disabled .ant-checkbox-inner{
      background: #777777;
      &:after{
        display: none !important;
      }
    }
    .ant-empty-description{
      color: #999999;
    }
    .currency-icon-table{
      height: 22px;
      svg{
        width: 16px;
        height: 16px;
      }
    }
  }

  .text-green{
    color: #28DD86 !important;
  }

  .text-gray{
    color: #999999 !important;
  }

  .table-btn{
    width: 120px;
    height: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FF9900;
    border: 1px #FF9900 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .ant-select-item{
    .ant-select-item-option-content{
      font-family: "Montserrat", Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.05em;
      color: #454545;
    }
  }

  //notification 
  :where(.css-dev-only-do-not-override-1km3mtt).ant-notification-notice {
    max-width: 50vw;
  }
  .ant-notification-notice-icon-info {
    color: #111;
  }

    ::-webkit-scrollbar {
      width: 4px;
      height: 0;
    }

    ::-webkit-scrollbar-track {
    background: transparent; 
    }
    
    ::-webkit-scrollbar-thumb {
      background: ${darkTheme.doteColor}; 
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }

    .ant-table-body {
      overflow-y: hidden !important;
      padding-bottom: 20px;
      ::-webkit-scrollbar {
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 30px;
      }
      ::-webkit-scrollbar-thumb {
        background: #ff00e2;
        border-radius: 30px;
      }
    }
`;

export default GlobalStyle;
