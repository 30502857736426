import { combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import themeReducer from "../features/themeToggle/themeSlice";
import loadingActionReducer from "features/loadingAction/loadingActionSlice";
import connectWalletReducer from "features/connectWallet/connectWalletSlice";
import userLoginReducer from "features/userLogin/userLoginSlice";


import history from "utils/history";

const combinedReducer = combineReducers({
  router: connectRouter(history),
  themeToggle: themeReducer,
  loadingAction: loadingActionReducer,
  connectWallet: connectWalletReducer,
  userLogin: userLoginReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "logOut") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
